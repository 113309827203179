<template>
  <div>
    <BreadCrumb :items="breadCrumb" />
    <EditBookForm :value="book" />
  </div>
</template>

<script>
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import EditBookForm from '@/components/form/EditBookForm.vue';

export default {
  name: 'BackendCreateBook',
  components: { BreadCrumb, EditBookForm },

  data() {
    return {
      book: {},
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'Books List', to: { name: 'bke-book-list' } },
        { text: 'New', active: true },
      ];
    },
  },
};
</script>
